<script setup lang="ts">
import useSportsBook from '~/composables/useSportsBook';
import useUser from '~/composables/useUser';
import useWallet from '@/composables/useWallet';

const { betSlip, emptyBetSlip, deleteBet, placeBet } = useSportsBook();
const { balance, loadBalance } = useWallet();
const { userId, externalId } = useUser();
const showBetSlip = ref(false);
const betAmount = ref();
const loading = ref(false);

function getWinner(bet: Bet) {
  if (bet.winner === 'teamA') return bet.game.teamA;
  if (bet.winner === 'teamB') return bet.game.teamB;
  return 'Draw';
}

const betSlipTitle = computed(() => {
  if (betSlip.value.length === 1) {
    return 'Single';
  }
  if (betSlip.value.length > 1) {
    return 'Combination';
  }
  return 'Single'
});

const totalOdds = computed(() => {
  if (!betSlip.value.length) return 0;
  return betSlip.value.reduce((acc, bet) => {
    return acc * bet.game[`${bet.winner}Odds`];
  }, 1).toFixed(2);
});

const canPlaceBet = computed(() => {
  if (!betAmount.value) return false;
  return parseFloat(betAmount.value) < parseFloat(balance.value);
})

const handleDeleteBet = (bet: Bet) => {
  deleteBet(bet);
}

const placeBetButtonText = computed(() => {
  if (loading.value) return '';
  return 'Place bet';
});

const handlePlaceBetClick = async () => {
  loading.value = true;

  try {
    const res = await placeBet(betAmount.value, balance.value, (balance.value - betAmount.value), userId.value, externalId.value);
    loadBalance();
    betAmount.value = undefined;
    showBetSlip.value = false;
    emptyBetSlip();
  } catch (e) {
    alert('Could not place bet');
    console.error(e);
  }

  loading.value = false;
}
</script>

<template>
  <div class="bet-slip" :class="{ 'bet-slip--tease': betSlip.length, 'bet-slip--show': betSlip.length && showBetSlip }">
    <header class="bet-slip__header" @click="showBetSlip = !showBetSlip">
      {{ betSlipTitle }} @ {{ totalOdds }}
    </header>
    <section class="bet-slip__body">
      <div class="bet-slip__row" v-for="bet in betSlip">
        <div class="row row--gap-8">
          <div class="column column--wrap">
            <button @click="handleDeleteBet(bet)" class="delete">
              <fa :icon="['fasl', 'trash-can-xmark']" />
            </button>
          </div>
          <div class="column">
            <div>
              {{ bet.game.teamA }} - {{ bet.game.teamB }}
            </div>
            <div class="bet-slip__row__winner">
              Full time - {{ getWinner(bet) }}
            </div>
          </div>
          <div class="column column--wrap">
            {{ bet.game[bet.winner + 'Odds'].toFixed(2) }}
          </div>
        </div>
      </div>
    </section>

    <footer class="bet-slip__footer">
      <div class="row row--sp row--gap-8">
        <div class="column">
          <div class="row row--gap-8">
            €
            <input type="number" class="bet-input" placeholder="Bet amount" v-model="betAmount">
          </div>

        </div>
        <div class="">
          <button class="button button--smaller" @click="handlePlaceBetClick" :disabled="!canPlaceBet || loading"
            :class="{ 'button--loading': loading }">{{ placeBetButtonText }}</button>
        </div>
      </div>
      <div class="available-balance">
        Available balance: <strong>€{{ balance }}</strong>
      </div>
    </footer>
  </div>
</template>

<style scoped>
.bet-slip {
  position: fixed;
  bottom: 64px;
  left: 50%;
  margin-left: -170px;
  background: #3D4071;
  font-size: 14px;

  display: flex;
  flex-direction: column;
  width: 340px;
  transform: translateY(100%);
  transition: all ease .3s;
}

@media(min-width: 1024px) {
  .bet-slip {
    bottom: 0px;
    width: 375px;
    right: 50px;
    left: unset;
  }
}

input[type=number] {
  appearance: none;
  -webkit-appearance: none;
  font-size: 15px;
  padding: 0px 5px;
  border: none;
  outline: none;
  height: 30px;
  border-radius: 4px;
  font-weight: bold;
}

.bet-slip--tease {
  transform: translateY(calc(100% - 40px));
}

.bet-slip--show {
  transform: translateY(0);
}

.bet-slip__header {
  background: #FFEE52;
  color: #020926;
  padding: 10px;
  font-weight: 700;
  font-size: 16px;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.bet-slip__body {
  flex: 1;
  min-height: 1px;
  overflow: auto;
}

.bet-slip__footer {
  padding: 10px;
}

.bet-slip__row {
  padding: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, .05);
}

.bet-slip__row__winner {
  font-size: 12px;
  font-weight: 700;
}

.delete {
  padding: 0;
  background: transparent;
  height: 34px;
  width: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}

.delete:hover {
  background: rgba(255, 255, 255, 0.1);
}

.delete svg {
  display: block;
  height: 20px;
}

.available-balance {
  font-size: 12px;
  text-align: right;
  margin-top: 8px;
}

.bet-input {
  width: 100%;
}
</style>