<script setup lang="ts">
import { ref, computed } from 'vue';
const route = useRoute();

import { splitPhoneNumber } from '@/scripts/utils';

const emit = defineEmits(['show-login']);

const email = ref(undefined);
const name = ref(undefined);
const number = ref<string | undefined>(undefined);
const password = ref(undefined);
const loading = ref(false);

const canSave = computed(() => {
  if (!email.value) return false;
  if (!name.value) return false;
  if (!number.value) return false;
  if (!password.value) return false;

  return !loading.value;
});


const handleCreateAccount = async () => {
  loading.value = true;
  const splitName = name.value.split(' ');
  let phoneNumber;
  try {
    phoneNumber = splitPhoneNumber(number.value.toString());
  } catch (e) {
    alert('Phone number is in the wrong format');
    loading.value = false;
    return;
  }
  const payload = {
    timestamp: new Date().toISOString(),
    origin: 'starry-spins',
    affiliate_reference: route.query.btag || 'wges-direct',
    email: email.value,
    firstName: splitName[0],
    lastName: splitName[1] || '',
    mobilePrefix: phoneNumber.prefix,
    mobileNumber: phoneNumber.number,
  }

  const req = await fetch('/api/user/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
  loading.value = false;
  if (!req.ok) {
    console.error(await req.json());
    if (req.status === 409) {
      alert('Email already exists')
    } else {
      alert('Could not create account')
    }
    return;
  }

  emit('show-login');
}

</script>

<template>
  <div class="create-account-form">
    <form @submit.prevent="handleCreateAccount">
      <div class="form-row">
        <label>
          <div class="input-label">
            Email
          </div>
          <div class="input-wrapper">
            <div class="input-wrapper__icon">
              <fa :icon="['fasl', 'envelope']"></fa>
            </div>
            <input type="email" v-model="email" placeholder="Enter email address" class="input">
          </div>
        </label>
      </div>
      <div class="form-row">
        <label>
          <div class="input-label">
            Password
          </div>
          <div class="input-wrapper">
            <div class="input-wrapper__icon">
              <fa :icon="['fasl', 'lock']"></fa>
            </div>
            <input type="password" v-model="password" placeholder="Enter your password" class="input">
          </div>
        </label>
      </div>
      <div class="form-row">
        <label>
          <div class="input-label">
            Name
          </div>
          <div class="input-wrapper">
            <div class="input-wrapper__icon">
              <fa :icon="['fasl', 'user']"></fa>
            </div>
            <input type="text" v-model="name" placeholder="Enter a name" class="input">
          </div>
        </label>
      </div>
      <div class="form-row">
        <label>
          <div class="input-label">
            Phone number
          </div>
          <div class="input-wrapper">
            <div class="input-wrapper__icon">
              <fa :icon="['fasl', 'phone']"></fa>
            </div>
            <input type="tel" v-model="number" placeholder="Enter a phone number" class="input">
          </div>
        </label>
        <div class="phone-number-warning">
          Phone number should be in the format +46xxxxxxxxx
        </div>
      </div>
      <div class="button-wrapper">
        <button class="button" type="submit" style="width: 100%" :disabled="!canSave"
          :class="{ 'button--loading': loading }">Register</button>
        <div class="create-account-button" @click="$emit('show-login')">
          Login
        </div>
      </div>
    </form>
  </div>
</template>

<style scoped>
.create-account-form {
  width: 100%;
  max-width: 340px;
}

.phone-number-warning {
  font-size: 13px;
  margin-top: 4px;
}
</style>