import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
  faBars,
  faWallet,
  faMailboxFlagUp,
  faGift,
  faListCheck,
  faBan,
  faArrowRightFromBracket,
  faXmark,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faCreditCard,
  faBank,
  faCircle,
  faCheck,
  faEnvelope,
  faLock,
  faUser,
  faPhone,
  faHouse,
  faTrashCanXmark,
} from '@fasttrack-solutions/fontawesome-sharp-light-svg-icons';

import {
  faWallet as faWalletSolid,
  faHouse as faHouseSolid

} from '@fasttrack-solutions/fontawesome-sharp-solid-svg-icons';

library.add(
  faBars,
  faWallet,
  faMailboxFlagUp,
  faGift,
  faListCheck,
  faBan,
  faArrowRightFromBracket,
  faXmark,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faCreditCard,
  faBank,
  faCircle,
  faCheck,
  faEnvelope,
  faLock,
  faUser,
  faPhone,
  faWalletSolid,
  faHouse,
  faHouseSolid,
  faTrashCanXmark,
);

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('fa', FontAwesomeIcon);
  // Doing something with nuxtApp
});
