import useUser from '@/composables/useUser';

export default function useWallet() {
  const balance = useState('balance', () => 0);
  const { externalId } = useUser();

  function loadBalance(id?: number) {
    return new Promise(async (resolve) => {
      const balanceReq = await fetch(`/api/wallet/load?userId=${id || externalId.value}`);
      const balanceRes = await balanceReq.json();

      balance.value = balanceRes.balance;
      resolve(balance.value);
    })

  }

  return { loadBalance, balance };
}