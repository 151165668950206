<script setup>
import useUser from '@/composables/useUser';
import { onMounted } from 'vue';

const { getAuth } = useUser();

onMounted(() => {
  window.addEventListener('fasttrack-crm-authed', function () {
    if (window && window.fasttrack && window.fasttrack.enableCrmIntegration && window.fasttrack.enableCrmIntegration === true) {
      window.FasttrackCrm.loggedIn()
        .then(response => console.log(response))
        .catch(error => console.log(error));
    }
  });


  var fastTrackCrmScript = document.createElement('script');
  fastTrackCrmScript.async = true;
  fastTrackCrmScript.onload = function () {
    new window.FastTrackLoader();
  };


  var fastTrackCrmScriptSrc = `https://crm-lib-staging.fasttrack-solutions.com/loader/fasttrack-crm.js?v=${new Date().getTime()}`;
  fastTrackCrmScript.src = fastTrackCrmScriptSrc;
  document.body.appendChild(fastTrackCrmScript);

  window.addEventListener('fasttrack-crm-ready', () => {
    window.FasttrackCrm.init(undefined, undefined);
  });

})
</script>

<template></template>