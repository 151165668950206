import games from '@/data/games.json';

export interface Game {
  id: 0,
  teamA: string;
  teamAOdds: number
  teamB: string;
  teamBOdds: number
  tieOdds: number
  matchStart: string,
  sportsName: string,
  tournamentName: string;
}

export interface Bet {
  id: number;
  market: 'Winner';
  winnerOutcome: string;
  winner: string;
  game: Game;
  odds?: number;
}

export default function useSportsBook() {
  const betSlip = useState<Bet[]>('betslip', () => []);


  function addToBetSlip(bet: Bet) {
    const oldBetIndex = betSlip.value.findIndex(b => b.game.id === bet.game.id);
    if (oldBetIndex > -1) {
      const oldBet = betSlip.value[oldBetIndex];
      if (oldBet.winnerOutcome === bet.winnerOutcome) {
        betSlip.value.splice(oldBetIndex, 1);
      } else {
        betSlip.value.splice(oldBetIndex, 1, bet);
      }
      return;
    } else {
      betSlip.value.push(bet);
    }
  };

  function emptyBetSlip() {
    betSlip.value = [];
  }

  function deleteBet(bet: Bet) {
    const oldBetIndex = betSlip.value.findIndex(b => b.game.id === bet.game.id);
    betSlip.value.splice(oldBetIndex, 1);
  }

  async function placeBet(betAmount: number, balanceBefore: number, balanceAfter: number, userId: number, externalUserId: string) {
    const bets = []

    betSlip.value.forEach((bet) => {
      bets.push({
        event_name: `${bet.game.teamA} vs ${bet.game.teamB}`,
        'is_free_bet': false,
        'is_risk_free_bet': false,
        'market': '1X2',
        'match_start': bet.game.matchStart,
        'outcome': bet.winnerOutcome,
        'sports_name': bet.game.sportsName,
        'tournament_name': bet.game.tournamentName,
        'odds': bet.game[`${bet.winner}Odds`],
        'is_live': false,
      });
    });

    const bet = {
      activity_id: `${new Date().getMilliseconds()}`,
      activity_id_reference: '',
      amount: betAmount,
      bet_type: betSlip.value.length > 1 ? 'Multi' : 'Single',
      bets: bets,
      bonus_wager_amount: 0.0,
      balance_after: balanceAfter,
      balance_before: balanceBefore,
      currency: 'EUR',
      exchange_rate: 1,
      is_cashout: false,
      locked_wager_amount: 0.0,
      origin: 'starry-spins',
      timestamp: `${new Date().toISOString()}`,
      type: 'Bet',
      user_id: `${externalUserId}`,
      externalUserId: externalUserId,
      wager_amount: 0.0
    }

    try {
      const req = await fetch('/api/sportsbook/bet', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bet),
      });

      if (!req.ok) {
        throw new Error('Could not place bet');
      }
      return true;
    } catch (error) {
      throw new Error('Could not place bet');
    }
  }

  return { games, addToBetSlip, betSlip, emptyBetSlip, deleteBet, placeBet };
}
