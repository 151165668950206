import { computed } from 'vue';
export default function useUser() {

  const userCookie = useCookie('ft-ss-auth', { sameSite: 'none', secure: true });



  const userId = computed(() => {
    return userCookie.value?.User?.UserId;
  });

  const externalId = computed(() => {
    return userCookie.value?.externalId;
  });

  const isLoggedIn = computed(() => {
    return !!userId.value
  });

  const token = computed(() => {
    return userCookie.value?.Authentication?.AuthToken;
  });

  const email = computed(() => {
    return userCookie.value?.email;
  });

  async function setAuth(payload: Object) {
    userCookie.value = payload;
  }

  function getAuth() {
    return userCookie.value;
  }

  async function clearAuth() {
    userCookie.value = undefined;
    window.ftcrm = undefined;
  }

  return { userId, externalId, isLoggedIn, token, email, setAuth, clearAuth, getAuth };
}