import revive_payload_client_CyLnf7iLkX from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_rollup@4.24.0_vite@5.4.8/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_RLvEfB4B7O from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_rollup@4.24.0_vite@5.4.8/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_a2MmGCFXbk from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_rollup@4.24.0_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_IcrRHtVUYZ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_rollup@4.24.0_vite@5.4.8/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_pOTOPrN3dC from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_rollup@4.24.0_vite@5.4.8/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_iD5p6kHP8T from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_rollup@4.24.0_vite@5.4.8/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_TiLKb7KVB6 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_rollup@4.24.0_vite@5.4.8/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_mdrCD3tiPH from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_rollup@4.24.0_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_r1k9hxYeVF from "/opt/buildhome/repo/node_modules/.pnpm/@sentry+nuxt@8.33.1_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+instru_oecmthpck5lr2l5hw2uis52bii/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/opt/buildhome/repo/.nuxt/sentry-client-config.mjs";
import fort_awesome_global_HS8duis0tN from "/opt/buildhome/repo/plugins/fort-awesome.global.ts";
export default [
  revive_payload_client_CyLnf7iLkX,
  unhead_RLvEfB4B7O,
  router_a2MmGCFXbk,
  payload_client_IcrRHtVUYZ,
  navigation_repaint_client_pOTOPrN3dC,
  check_outdated_build_client_iD5p6kHP8T,
  chunk_reload_client_TiLKb7KVB6,
  components_plugin_KR1HBZs4kY,
  prefetch_client_mdrCD3tiPH,
  sentry_client_r1k9hxYeVF,
  sentry_client_config_o34nk2sJbg,
  fort_awesome_global_HS8duis0tN
]