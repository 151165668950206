import { default as casinoifEn04H7tpMeta } from "/opt/buildhome/repo/pages/casino.vue?macro=true";
import { default as cheat_45panelfGvIw1efnFMeta } from "/opt/buildhome/repo/pages/cheat-panel.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as lotterywQmLzUlGYXMeta } from "/opt/buildhome/repo/pages/lottery.vue?macro=true";
import { default as _91slug_93AHwG5rloMjMeta } from "/opt/buildhome/repo/pages/play/[slug].vue?macro=true";
import { default as sportsbook0GUxWJog26Meta } from "/opt/buildhome/repo/pages/sportsbook.vue?macro=true";
import { default as user_45consentsFBZESOAV77Meta } from "/opt/buildhome/repo/pages/user-consents.vue?macro=true";
import { default as walletZSlO4FiQE2Meta } from "/opt/buildhome/repo/pages/wallet.vue?macro=true";
export default [
  {
    name: "casino",
    path: "/casino",
    component: () => import("/opt/buildhome/repo/pages/casino.vue")
  },
  {
    name: "cheat-panel",
    path: "/cheat-panel",
    component: () => import("/opt/buildhome/repo/pages/cheat-panel.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue")
  },
  {
    name: "lottery",
    path: "/lottery",
    component: () => import("/opt/buildhome/repo/pages/lottery.vue")
  },
  {
    name: "play-slug",
    path: "/play/:slug()",
    component: () => import("/opt/buildhome/repo/pages/play/[slug].vue")
  },
  {
    name: "sportsbook",
    path: "/sportsbook",
    component: () => import("/opt/buildhome/repo/pages/sportsbook.vue")
  },
  {
    name: "user-consents",
    path: "/user-consents",
    component: () => import("/opt/buildhome/repo/pages/user-consents.vue")
  },
  {
    name: "wallet",
    path: "/wallet",
    component: () => import("/opt/buildhome/repo/pages/wallet.vue")
  }
]