<script setup lang="ts">
import { ref, nextTick } from 'vue';
import useUser from '@/composables/useUser';
// const userId = useCookie('ft-ss-userid');

const { userId, setAuth } = useUser();

import useWallet from '@/composables/useWallet';

const emit = defineEmits(['show-create-account', 'login']);
const email = ref(undefined);
const password = ref(undefined);
const loading = ref(false);

const handleLogin = async () => {
  loading.value = true;
  const req = await fetch('/api/user/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email: email.value }),
  });

  loading.value = false;

  if (!req.ok) {
    alert('Could not log in, check to make sure your email and password are correct.');
    return;
  }
  const res = await req.json();
  setAuth(res.auth)
  // userId.value = res.id; //Set userId as token
  await nextTick();

  emit('login');
}

</script>

<template>
  <div class="login__form">
    <form @submit.prevent="handleLogin">
      <div class="form-row">
        <label>
          <div class="input-label">
            Email
          </div>
          <div class="input-wrapper">
            <div class="input-wrapper__icon">
              <fa :icon="['fasl', 'envelope']"></fa>
            </div>
            <input type="email" v-model="email" placeholder="Enter email address" class="input">
          </div>
        </label>
      </div>
      <div class="form-row">
        <label>
          <div class="input-label">
            Password
          </div>
          <div class="input-wrapper">
            <div class="input-wrapper__icon">
              <fa :icon="['fasl', 'lock']"></fa>
            </div>
            <input type="password" v-model="password" placeholder="Enter your password" class="input">
          </div>
        </label>
      </div>
      <div class="button-wrapper">
        <button class="button" :disabled="loading || !email || !password" :class="{ 'button--loading': loading }"
          style="width: 100%" type="submit">Login</button>
        <div class="create-account-button" @click="$emit('show-create-account')">
          Create account
        </div>
      </div>
    </form>
  </div>
</template>

<style scoped>
.login__form {
  width: 100%;
  max-width: 340px;
}
</style>